import React from 'react'

const Offline = () => {
  return (
    <div>
      <Offline/>
    </div>
  )
}

export default Offline
